import DataTable from "datatables.net"
import "datatables.net-bs4"
import "datatables.net-autofill"
import "datatables.net-autofill-bs4"
// import "jszip"
// import "pdfmake"
import "datatables.net-buttons"
import "datatables.net-buttons-bs4"
// import "buttons.colVis"
// import "buttons.flash"
// import "buttons.html5"
// import "buttons.print"
import "datatables.net-colreorder"
import "datatables.net-fixedcolumns"
import "datatables.net-fixedheader"
import "datatables.net-keytable"
import "datatables.net-responsive"
import "datatables.net-responsive-bs4"
import "datatables.net-rowgroup"
import "datatables.net-rowreorder"
import "datatables.net-select"
import "datatables.net-bs4"
import 'datatables.net-scroller-bs4'
import { mUtil } from '../assets/metro5/assets/demo/default/base/scripts.bundle.fix.js'

import '../assets/css/colors.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.css'
import '../css/datatables.css'
import '../assets/css/style_custom.css'
import '../../custom/css/custom.css'

let defaults = {
	"language": {
		"paginate": {
			"first": '<i class="la la-angle-double-left"></i>',
			"last": '<i class="la la-angle-double-right"></i>',
			"next": '<i class="la la-angle-right"></i>',
			"previous": '<i class="la la-angle-left"></i>'
		}
	}
};

if (mUtil.isRTL()) {
	defaults = {
		"language": {
			"paginate": {
				"first": '<i class="la la-angle-double-right"></i>',
				"last": '<i class="la la-angle-double-left"></i>',
				"next": '<i class="la la-angle-left"></i>',
				"previous": '<i class="la la-angle-right"></i>'
			}
		}
	}
}

$.extend(true, $.fn.dataTable.defaults, defaults);